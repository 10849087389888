import { SelectProps, SelectRef } from '@sortlist-frontend/design-system';
import { dehydrate, HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { forwardRef } from 'react';

import { NavigationData } from '_components/layout/Layout';
import { DEFAULT_LOCAL_ORIGIN_DOMAIN } from '_core/repos/public-api.repo';
import { domainCacheKey } from '_core/repos/public-api/domain.repo';

import { SearchTopicsInner } from './components';
import { SearchTopicsInnerForFilters } from './components/SearchTopicsInnerForFilters';

type Props = {
  page: string;
  cta: string;
  element: string;
  navigationData: NavigationData;
  mobile?: boolean;
  extraTopicId?: string;
  type: 'service' | 'location';
} & SelectProps;

export const getHost = (origin: string) => {
  if (['localhost', 'pr-'].some((host) => origin.includes(host))) {
    return DEFAULT_LOCAL_ORIGIN_DOMAIN;
  }
  return origin.replace('https://', '');
};

export const SearchTopics = forwardRef<SelectRef, Props>((props, ref) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5, // 5 minutes
      },
    },
  });

  queryClient.setQueryData(domainCacheKey(props.navigationData.origin), props.navigationData.domain);

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydrate(queryClient)}>
        {props.type === 'location' ? (
          <SearchTopicsInnerForFilters {...props} ref={ref} />
        ) : (
          <SearchTopicsInner {...props} ref={ref} />
        )}
      </HydrationBoundary>
    </QueryClientProvider>
  );
});
