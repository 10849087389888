import { cx } from '@emotion/css';
import { SearchRounded } from '@mui/icons-material';
import { Option, Select, SelectProps, SelectRef } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { useDebounceCallback } from '@sortlist-frontend/utils';
import { forwardRef, Fragment, useState } from 'react';

import { NavigationData } from '_components/layout/Layout';
import { useNavigationContext } from '_core/context/navigation-context';
import { useTopics } from '_core/repos/public-api/topics.repo';
import { trackAgenciesSearched } from '_features/search/trackers';

type Props = {
  page: string;
  cta: string;
  element: string;
  navigationData: NavigationData;
  mobile?: boolean;
  extraTopicId?: string;
  type: 'service' | 'location';
} & SelectProps;

export const SearchTopicsInner = forwardRef<SelectRef, Props>((props, ref) => {
  const {
    placeholder,
    navigationData,
    mobile = false,
    className,
    page,
    cta,
    element,
    type,
    extraTopicId,
    onChange,
    ...selectProps
  } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');

  const { domain, locale, origin } = navigationData;

  const {
    data: topics,
    isLoading: isTopicsLoading,
    refetch: refetchTopics,
  } = useTopics(
    domain,
    domain != null ? [extraTopicId ?? domain?.location?.id] : [],
    locale,
    type,
    searchValue,
    origin,
    domain != null,
  );
  const router = useNavigationContext();

  const options: Option[] = topics?.map((topic) => ({ value: topic.url, label: topic.name })) ?? [];
  const handleInputChange = useDebounceCallback((searchValue: string) => {
    setSearchValue(searchValue);
  }, 300);

  return (
    <Select
      ref={ref}
      id={mobile ? 'search-service-select-mobile' : 'search-service-select'}
      className={cx('flex search-service-select', className)}
      placeholder={placeholder}
      options={options}
      noOptionsMessage={() => <Fragment>{t('common:navbar.menu.whatServiceNoOptions')}</Fragment>}
      value={null}
      instanceId={mobile ? 'search-service-select-mobile' : 'search-service-select'}
      isLoading={isTopicsLoading}
      inputId={mobile ? 'service-search-mobile' : 'service-search'}
      onInputChange={handleInputChange}
      onFocus={() => {
        if (topics == null) {
          refetchTopics();
        }
      }}
      onChange={(option, info) => {
        if (onChange != null) {
          onChange(option, info);
          return;
        }
        const currentUrl = new URL(window.location.href);
        const selectedTopicUrl = new URL((option as Option).value);

        if (currentUrl.pathname === selectedTopicUrl.pathname) return;

        trackAgenciesSearched({
          page,
          cta,
          element,
          query: searchValue,
          topic: (option as Option).label,
          url: (option as Option).value,
        });

        const url = new URL(window.location.href);
        const searchParams = url.searchParams.toString();
        const targetUrl =
          searchParams.length > 0 ? `${(option as Option).value}?${searchParams}` : (option as Option).value;
        router.push(targetUrl, undefined, { scroll: true });
        setSearchValue('');
      }}
      components={{
        ...(searchValue == null ? { Menu: () => null } : {}),
        DropdownIndicator: () => <SearchRounded fontSize="inherit" />,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
      }}
      {...selectProps}
    />
  );
});
