import { Snackbar, SnackbarProps } from '@sortlist-frontend/design-system';
import { Monitor } from '@sortlist-frontend/mlm';
import { hasStorage, isBrowser } from '@sortlist-frontend/utils';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { GetUrlType, PublicAppLinks } from '_core/links/public-app-links';
import { Expertise } from '_core/repos/expertises.repo';
import { Skill } from '_types/generic';

type BriefingModifiers = {
  matching?: string;
  rightProvider?: string;
};

export type BriefingOptions = {
  page: string;
  address?: string;
  placeId?: string;
  expertise?: number;
  expertiseObject?: Partial<Expertise>;
  skills?: string[];
  skillObjects?: Skill[];
  iso31661?: string;
  modifiers?: BriefingModifiers;
};

export type PublicAppContextState = {
  domainInfo: DomainInfo | null;
  canonical: string;
  query?: string;
  locale: string;
  getUrl: GetUrlType;
  windowWidth?: number | null;
  pageData?: Record<string, unknown>;
  briefingOptions?: BriefingOptions;
  specialProps?: Record<string, unknown>; // It can hold whatever you want
  openUserConsentModal: boolean;
  setOpenUserConsentModal: Dispatch<SetStateAction<boolean>>;
  setSpecialProps?: Dispatch<SetStateAction<Record<string, unknown> | undefined>>; // When using this, don't overwrite other values, so do a spread and then set your value
  abValue?: string;
  showSnackbar: Dispatch<SetStateAction<SnackbarProps>>;
};

const PublicAppContext = createContext<PublicAppContextState | undefined>(undefined);

type Props = {
  canonical: string;
  query?: string;
  origin: string;
  locale: string | null;
  children: ReactNode;
  windowWidth?: number | null;
  pageData?: Record<string, unknown>;
  abValue?: string;
  briefingOptions?: BriefingOptions;
  specialProps?: Record<string, unknown>; // It can hold whatever you want
  openUserConsentModal?: boolean;
  setOpenUserConsentModal?: Dispatch<SetStateAction<boolean>>;
  setSpecialProps?: Dispatch<SetStateAction<Record<string, unknown> | undefined>>; // When using this, don't overwrite other values, so do a spread and then set your value
};

export const PublicAppContextProvider = (props: Props) => {
  const {
    children,
    canonical,
    query,
    origin,
    locale: lang,
    pageData,
    briefingOptions,
    windowWidth,
    specialProps: specialPropsValue,
    abValue,
  } = props;

  const domainInfo = DomainInfo.getFromOrigin(origin);
  const [openUserConsentModal, setOpenUserConsentModal] = useState(false);
  const [specialProps, setSpecialProps] = useState(specialPropsValue);
  const [snackBarOptions, setSnackBarOptions] = useState<SnackbarProps>({});
  const [localStorageError, setLocalStorageError] = useState(false);

  const locale = lang ?? 'en';
  const { getUrl } = new PublicAppLinks({ origin, locale });

  useEffect(() => {
    if (!hasStorage() && isBrowser() && !localStorageError) {
      Monitor.captureException(new Error('STORAGE ACCESS DENIED'));
      setLocalStorageError(true);
    }
  }, []);

  return (
    <PublicAppContext.Provider
      value={{
        pageData,
        briefingOptions,
        domainInfo,
        locale,
        getUrl,
        windowWidth,
        openUserConsentModal,
        setOpenUserConsentModal,
        canonical,
        query,
        specialProps,
        setSpecialProps,
        abValue,
        showSnackbar: setSnackBarOptions,
      }}>
      {children}
      <Snackbar
        open={!!snackBarOptions.message}
        onClose={() => {
          setSnackBarOptions({});
        }}
        {...snackBarOptions}
      />
    </PublicAppContext.Provider>
  );
};

export const usePublicAppContext = () => {
  const context = useContext(PublicAppContext);
  if (context === undefined || context === null) {
    throw new Error(`usePublicAppContext must be used within an PublicAppContextProvider`);
  }
  return context;
};
