import { forwardRef, ReactElement, useEffect, useState } from 'react';
import ReactSelect, { type GroupBase, type Props, type SelectInstance } from 'react-select';
import ReactCreatableSelect, { type CreatableProps } from 'react-select/creatable';

import type { Size } from '../types';
import { CustomClearIndicator } from './CustomClearIndicator';
import { CustomDropdownIndicator } from './CustomDropdownIndicator';
import { CustomRemoveIndicator } from './CustomRemoveIndicator';
import { FormElementWrapper } from './FormElementWrapper';
import { getSelectStyles } from './styles';
import * as S from './styles';

export type SelectProps = {
  isCreatable?: boolean;
  className?: string;
  error?: boolean;
  errorIcon?: boolean;
  errorMessage?: string;
  height?: Size | '';
  htmlFor?: string;
  maxMenuWidth?: string;
  labelIcon?: ReactElement;
  innerIconLeft?: ReactElement;
  label?: ReactElement | string;
  placeholder?: string;
  tip?: boolean;
  tipIcon?: boolean;
  tipMessage?: string;
  prependComponent?: ReactElement;
  stretch?: boolean;
  date?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  onCreateOption?: CreatableProps<unknown, boolean, GroupBase<unknown>>['onCreateOption'];
} & Props;

export type SelectRef = SelectInstance<unknown, boolean, GroupBase<unknown>>;

/* eslint-disable sonarjs/cognitive-complexity */
export const Select = forwardRef<SelectRef, SelectProps>((props, ref) => {
  const {
    isCreatable,
    className,
    error,
    errorMessage,
    height,
    htmlFor,
    labelIcon,
    errorIcon,
    innerIconLeft,
    label,
    onChange,
    placeholder,
    prependComponent,
    tip,
    tipIcon,
    tipMessage,
    stretch,
    disabled,
    date,
    maxMenuWidth,
    components,
    isLoading,
    isClearable,
    ...restProps
  } = props;

  const [isTipIconVisible, setTipIconVisible] = useState(false);
  const [isErrorIconVisible, setErrorIconVisible] = useState(false);
  const [isInnerIconLeftVisible, setIsInnerIconLeftVisible] = useState<boolean>(false);

  useEffect(() => {
    if (innerIconLeft) {
      setIsInnerIconLeftVisible(true);
    }
  }, [innerIconLeft]);

  useEffect(() => {
    if (tip && tipMessage && tipIcon) {
      setTipIconVisible(true);
    }
    if (error && errorIcon && errorMessage) {
      setErrorIconVisible(true);
    }
  }, [tip, tipIcon, tipMessage, error, errorIcon, errorMessage]);

  useEffect(() => {
    if (!error && !errorIcon && !!tipMessage && tipIcon) {
      setTipIconVisible(true);
    }
  }, [error, errorIcon, tipMessage, tipIcon]);

  useEffect(() => {
    if (error && errorIcon && !!errorMessage && !disabled) {
      setErrorIconVisible(true);
    }
  }, [error, errorIcon, errorMessage, disabled]);

  const select = getSelectStyles(props);

  return (
    <FormElementWrapper
      dataTestId="select"
      className={className}
      disabled={disabled}
      error={error}
      errorIcon={errorIcon}
      errorMessage={errorMessage}
      height={height}
      labelIcon={labelIcon}
      label={label}
      tipMessage={tipMessage}>
      <S.FormContainer error={error} disabled={disabled} fieldStyle={'select'}>
        {prependComponent && (
          <S.PrependWrapper data-testid="prepend-wrapper" className="bg-secondary-100">
            {prependComponent}
          </S.PrependWrapper>
        )}
        {isInnerIconLeftVisible && (
          <S.InnerIconLeft
            data-testid="select-inner-icon"
            error={error}
            disabled={disabled}
            className="absolute height-100 layout-column layout-align-start-center pl-12 py-12">
            {innerIconLeft}
          </S.InnerIconLeft>
        )}
        {isCreatable ? (
          <ReactCreatableSelect
            styles={select}
            placeholder={placeholder}
            isDisabled={disabled}
            isClearable={isClearable}
            onChange={onChange}
            components={{
              IndicatorSeparator: components?.IndicatorSeparator || (() => null),
              ClearIndicator: components?.ClearIndicator || CustomClearIndicator,
              MultiValueRemove: components?.MultiValueRemove || CustomRemoveIndicator,
              DropdownIndicator: components?.DropdownIndicator || CustomDropdownIndicator,
              ...components,
            }}
            classNamePrefix="react-select"
            ref={ref}
            {...restProps}
          />
        ) : (
          <ReactSelect
            styles={select}
            placeholder={placeholder}
            isDisabled={disabled}
            isClearable={isClearable}
            isLoading={isLoading}
            onChange={onChange}
            components={{
              IndicatorSeparator: components?.IndicatorSeparator || (() => null),
              ClearIndicator: components?.ClearIndicator || CustomClearIndicator,
              MultiValueRemove: components?.MultiValueRemove || CustomRemoveIndicator,
              DropdownIndicator: components?.DropdownIndicator || CustomDropdownIndicator,
              ...components,
            }}
            classNamePrefix="react-select"
            ref={ref}
            {...restProps}
          />
        )}
        {(isTipIconVisible || isErrorIconVisible) && (
          <S.InnerIconRight
            data-testid="select-helper-icon"
            disabled={disabled}
            error={error}
            title={errorMessage || tipMessage}
            className={'absolute height-100 layout-column layout-align-start-center pr-12 py-12'}
          />
        )}
      </S.FormContainer>
    </FormElementWrapper>
  );
});
